import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';
import request from '~/utils/Request';

import Viewer from './Viewer';

export default () => {
    const [codes, setCodes] = useState([]);
    const [apiStatus, setApiStatus] = useState({
        loading: false, error: null, offset: 0, limit: 50, nextOffset: 0, total: 0, filter: {}
    });

    const fetchPage = newApiStatus => {
        const {offset: curOffset, limit: curLimit} = newApiStatus;
        setApiStatus({...apiStatus, loading: true, error: null});
        console.log(`fetch page ${curOffset} ${curLimit}`);
        const qs = queryString.stringify({offset: curOffset, limit: curLimit});
        return request(`/code?${qs}`)
            .then(resp => resp.json())
            .then(({total: serverTotal, codes: serverCodes}) => {
                setApiStatus({...apiStatus, ...newApiStatus, total: serverTotal});
                setCodes(serverCodes);
            })
            .catch(err => {
                setApiStatus({...apiStatus, loading: false, error: `${err}`});
            })
        ;
    }

    useEffect(() => {fetchPage(apiStatus);}, []);

    return <>
        <Helmet>兑换码 | Groobo</Helmet>
        <Viewer 
            loading={apiStatus.loading}
            error={apiStatus.error}
            codes={codes}
        
            pageCurrent={Math.max(Math.ceil(apiStatus.nextOffset / apiStatus.limit), 1)}
            pageTotal={apiStatus.total}
            pageSize={apiStatus.limit}
            pageOnChange={(page, pageSize) => fetchPage({...apiStatus, offset: (page - 1) * pageSize, limit: pageSize})}
            pageOnShowSizeChange={(current, size) => fetchPage({...apiStatus, offset: (current - 1) * size, limit: size})}
        />
    </>;
};