import React from 'react';

/* eslint-disable react/prop-types */
class SimpleErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error);
        console.error(errorInfo);
    }

    render() {
        const {error} = this.state;
        if (error) {
            return <div>
                <h1>Error</h1>
                <pre><code>{`${error.stack}`}</code></pre>
            </div>;
        }

        const {children} = this.props;

        return children;
    }
}

export default SimpleErrorBoundary;
